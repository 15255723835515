<template>
  <b-overlay :show="!ready" variant="dark" opacity="0.85" blur="2px" rounded="sm" class="h-100">
    <b-input-group class="change-quantity">
      <b-input-group-prepend v-if="others" class="d-flex justify-content-center align-items-center cursor-pointer" @click="decreaseQuantity()">
        <div class="input-group-text px-0.5 border-primary">
          <feather-icon :icon="offerProduct.quantity == '1' ? 'TrashIcon' : 'MinusIcon'" :class="{ 'text-danger': offerProduct.quantity == '1' }" size="22" />
        </div>
      </b-input-group-prepend>
      <cleave
        :id="`${idPrefix}_${productId}`"
        ref="input"
        v-model="offerProduct.quantity"
        :options="cleaveOptions"
        class="form-control h-auto border-primary"
        :class="{ rounded: !others }"
        @focus.native="focusQuantity = true"
        @keyup.native="quantityInput"
        @keyup.native.enter="changeQuantity()"
      />

      <b-input-group-append v-if="others" class="d-flex justify-content-center align-items-center cursor-pointer rounded-r-0">
        <div v-if="focusQuantity" class="input-group-text px-0.5 border-primary" @click="changeQuantity()">
          <feather-icon icon="CheckCircleIcon" size="22" class="text-success" />
        </div>
        <div v-else class="input-group-text px-0.5 border-primary" @click="increaseQuantity()">
          <feather-icon icon="PlusIcon" size="22" />
        </div>
      </b-input-group-append>
      <b-input-group-append class="border-primary d-flex justify-content-center align-items-center px-0.5 rounded-r-1 bg-gray">
        <span class="small">{{ $t(`unit.${unitId}`) }} </span>
      </b-input-group-append>
    </b-input-group>
  </b-overlay>
</template>

<style lang="scss">
.change-quantity {
  height: 100%;
  min-height: 3rem;
  .form-control {
    text-align: center;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
  .input-group-prepend,
  .input-group-append {
    height: 100%;
    min-height: 3rem;
    .input-group-text {
      height: 100%;
      min-height: 3rem;
    }
  }
}
</style>

<script>
import { SET_NOTIFICATION } from '@/store/services/notification-service'
import { REMOVE_OFFER, UPDATE_OFFER } from '@/store/services/offer-service'
import { BInputGroup, BInputGroupAppend, BInputGroupPrepend, BOverlay } from 'bootstrap-vue'
import Cleave from 'vue-cleave-component'
import { mapGetters } from 'vuex'

export default {
  name: 'change-quantity',
  props: {
    product: { required: false },
    productId: { required: true },
    unitId: { required: true },
    others: { required: false, default: true },
    idPrefix: { required: false, default: 'offer_item' }
  },
  emits: ['changeQuantity'],
  components: {
    BInputGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
    BOverlay,

    Cleave
  },
  data() {
    return {
      offerProduct: undefined,
      ready: false,
      focusQuantity: false
    }
  },
  created() {
    this.offerProduct = this.offerList.find(x => x.product_id === this.productId && x.unit_id === this.unitId)
    this.ready = true
  },
  computed: {
    ...mapGetters(['offerList', 'getLocale']),
    cleaveOptions() {
      switch (this.getLocale) {
        case 'tr-TR':
          return {
            numeral: true,
            numeralPositiveOnly: true,
            numeralDecimalScale: 0,
            numeralDecimalMark: ',',
            delimiter: '.'
          }
        default:
          return {
            numeral: true,
            numeralPositiveOnly: true,
            numeralDecimalScale: 0,
            numeralDecimalMark: '.',
            delimiter: ','
          }
      }
    }
  },
  methods: {
    changeQuantity() {
      let quantity = this.offerProduct.quantity * 1
      if (quantity <= 0) {
        this.removeItemFromOfferList()
      } else {
        this.$store.commit(UPDATE_OFFER, {
          product_id: this.offerProduct.product_id,
          unit_id: this.unitId,
          quantity
        })
        this.$store.commit(SET_NOTIFICATION, {
          status: true,
          title: this.$t('offer.list'),
          message: this.$t('offer.update_offer_message')
        })
      }

      this.focusQuantity = false
      this.$emit('changeQuantity')
    },
    removeItemFromOfferList() {
      this.$store.commit(REMOVE_OFFER, {
        product_id: this.offerProduct.product_id,
        unit_id: this.unitId
      })
      this.product?.units?.forEach(unit => {
        if (unit.unit2_id === this.unitId) {
          unit.quantity = 1
        }
      })
      this.$store.commit(SET_NOTIFICATION, {
        status: true,
        title: this.$t('offer.list'),
        message: this.$t('offer.remove_offer_message')
      })
    },
    decreaseQuantity() {
      this.offerProduct.quantity--
      this.product?.units?.forEach(unit => {
        if (unit.unit2_id === this.unitId) {
          unit.quantity = +this.offerProduct.quantity
        }
      })
      this.changeQuantity()
    },
    increaseQuantity() {
      this.offerProduct.quantity++
      this.product?.units?.forEach(unit => {
        if (unit.unit2_id === this.unitId) {
          unit.quantity = +this.offerProduct.quantity
        }
      })
      this.changeQuantity()
    },
    quantityInput() {
      let q = this.offerProduct.quantity * 1
      this.product?.units?.forEach(unit => {
        if (unit.unit2_id === this.unitId) {
          unit.quantity = q
        }
      })
    }
  },
  watch: {
    unitId(val) {
      this.offerProduct = this.offerList.find(x => x.product_id === this.productId && x.tra_unit_id === val)
    }
  }
}
</script>
